import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { api } from '../utils';
import { translate } from '../utils/localization-helper';
import DynamicForm from './DynamicForm';
import { colors } from '../theme';

function OrderClientName({ orderInfo, onCompleteFunction, initialData}) {
    const navigate = useNavigate();
    const { config, isAuthenticated } = useAuth();

    useEffect(() => {
    }, []);

    const saveCustomHandler = async (data) => {
        await api.jsonRpcRequest('orders.update.client', { hash: orderInfo.hash, full_name: data.full_name , email: data.email });
        if(onCompleteFunction) onCompleteFunction()
    };

    const formConfig = {
        'fields': [
            {
                'title': translate('OrdersPage.table.fields.client_full_name'),
                'key': 'full_name',
                'type': 'text',
                'required': true,
            },
            {
                'title': translate('OrdersPage.table.fields.client_email'),
                'key': 'email',
                'type': 'text',
                'required': false,
            },
        ]
    };

    return (
        <div>
            <DynamicForm formConfig={formConfig} minimal={true} saveCustomHandler={saveCustomHandler} initialData={initialData} />
        </div>
    );
}

export default OrderClientName;
