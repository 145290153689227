import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { api } from '../utils';
import { translate } from '../utils/localization-helper';
import DynamicForm from './DynamicForm';
import { colors } from '../theme';

function formatDateShort(isoDateString) {
    var date = new Date(isoDateString);
    var day = date.getDate().toString().padStart(2, '0');
    var month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Month starts from 0
    var year = date.getFullYear();
    var hours = date.getHours().toString().padStart(2, '0');
    var minutes = date.getMinutes().toString().padStart(2, '0');
    return day + "." + month + " " + hours + ":" + minutes;
  }

function OrderSendMail({ orderInfo, onCompleteFunction, initialData}) {
    const navigate = useNavigate();
    const { config, isAuthenticated } = useAuth();
    const [objects, setObjects] = useState([]);

    const loadData = async (value, key) => {
        let data = await api.jsonRpcRequest('orders.info.mails.mailbox', { hash: orderInfo.hash });
        setObjects(data);
    };

    useEffect(() => {
        loadData();
    }, []);

    const saveCustomHandler = async (data) => {
        await api.jsonRpcRequest('orders.info.mails.send', { hash: orderInfo.hash, subject: data.subject, body: data.body, mailbox_id: data.mailbox_id });
        if(onCompleteFunction) onCompleteFunction()
    };

    const formConfig = {
        'fields': [
            {
                'title': 'From',
                'key': 'mailbox_id',
                'type': 'select',
                'options': objects ? objects.map(item => ({ value: item.id, label: item.email })) : [],
            },
            {
                'title': 'Subject',
                'key': 'subject',
                'type': 'text',
                'options': objects,
                'required': true,
            },
            {
                'title': 'Body',
                'key': 'body',
                'type': 'textarea',
                'options': objects,
                'required': true,
            },
        ]
    };

    return (
        <div>
            <DynamicForm formConfig={formConfig} minimal={true} saveCustomHandler={saveCustomHandler} initialData={initialData} />
        </div>
    );
}

export default OrderSendMail;
